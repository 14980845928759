import { useStaticQuery, graphql } from 'gatsby';

import { Seo, SharpImage } from '../models';

interface MainPageHeaderData {
    seo: Seo;
    banner_article_bottom: SharpImage;
    banner_main: SharpImage;
    banner_main_mobile: SharpImage;
}

export const useBlogPageData = (): MainPageHeaderData => {
    const {
        wpPage: {
            seo,
            article_common_acf: {
                banner_article_bottom,
                banner_main,
                banner_main_mobile,
            },
        },
    } = useStaticQuery(
        graphql`
            {
                wpPage(common_acf: { id: { eq: "blog" } }) {
                    seo {
                        ...Seo
                    }
                    article_common_acf {
                        banner_article_bottom: bannerArticleBottom {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 100)
                                }
                            }
                        }
                        banner_main: bannerMain {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 500)
                                }
                            }
                        }
                        banner_main_mobile: bannerMainMobile {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 500)
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return {
        banner_article_bottom,
        banner_main,
        banner_main_mobile,
        seo,
    };
};
