import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { FONT_GOTHAM_LIGHT, FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { SharpImage } from '../../models';
import { Box } from '../Box';
import { CompatibleImg } from '../Img';
import { COLORS } from '../../constants';

interface ArticleProps {
    image: SharpImage;
    title: string;
    url: string;
    excerpt: string;
}
const MAX_TITLE_LENGTH = 40;
const MAX_DESCRIPTION_LENGTH = 90;

const cutText = (text, length) =>
    text.length > length ? `${text?.substring(0, length)}...` : text;

const MorePostItem: React.FC<ArticleProps> = ({
    image,
    title,
    url,
    excerpt,
}) => {
    return (
        <Container to={`${url}`} rel="nofollow">
            <HeaderImage height="178px">
                <CompatibleImg
                    imageStyle={{ height: '100%', width: '100%' }}
                    image={image?.localFile}
                />
            </HeaderImage>
            <Title
                dangerouslySetInnerHTML={{
                    __html: cutText(title, MAX_TITLE_LENGTH),
                }}
            />
            <Description
                dangerouslySetInnerHTML={{
                    __html: `${cutText(
                        excerpt,
                        MAX_DESCRIPTION_LENGTH
                    )} <span style="color: ${
                        COLORS.darkGray
                    }">Читать далее</span>`,
                }}
            />
        </Container>
    );
};

const Title = styled.div`
    color: #000000;
    font-size: 18px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 20px;
    margin-bottom: 8px;
`;

const Description = styled.div`
    color: #000000;
    font-size: 16px;
    font-family: ${FONT_GOTHAM_LIGHT};
    line-height: 18px;
`;

const HeaderImage = styled(Box)`
    margin-bottom: 28px;
    border-radius: 15px;
    overflow: hidden;

    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);

    transition: all 0.2s ease-in-out;
`;

const Container = styled(Link)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 32px;
    padding: 0 12px;

    &:hover {
        ${HeaderImage} {
            box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.2),
                0 1px 15px 0 rgba(25, 27, 26, 0.2);
        }
    }
`;

export default MorePostItem;
