import React from 'react';

import { SharpImage } from '../../models';
import { Flex } from '../Box';

import MorePostItem from './MorePostItem';

interface ArticleProps {
    posts: {
        image: SharpImage;
        title: string;
        url: string;
        excerpt: string;
    }[];
}

const MorePostsList: React.FC<ArticleProps> = ({ posts }) => {
    return (
        <Flex flexDirection={['column', 'column', 'row']}>
            {posts?.map((item) => (
                <MorePostItem key={item.title} {...item} />
            ))}
        </Flex>
    );
};

export default MorePostsList;
