import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus';
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import {
    RichTextView,
    device,
    ContentMain,
    HiddenLabel,
} from '../components/commonStyled';
import {
    ROUTES,
    SCRIPT_ADTHIS_PATH,
    SITE_URL,
    COMPANY_NAME,
} from '../constants';
import { FONT_GOTHAM_LIGHT } from '../styles/fonts';
import { useExternalScript } from '../hooks/useExternalStript';
import { useBlogPageData } from '../queries/useBlogPageData';
import { getAbsoluteImageUrl, parseHeaders, scrollToBlock } from '../utils';
import PostNav from '../components/PostNav';
import { Box, Flex } from '../components/Box';
import MorePostsList from '../components/MorePostsList';
import { Seo, SharpImage } from '../models';

const onAnkorClick = (element: HTMLAnchorElement) => (e) => {
    e.preventDefault();
    const id = element.getAttribute('href').slice(1);

    scrollToBlock(`a[name="${id}"]`, { offset: -230 });
};

const applyListenersToAnkors = (rootElement: HTMLDivElement) => {
    const allLinks = Array.from(rootElement?.getElementsByTagName('a') || []);
    const ankors = allLinks.filter((element) =>
        element.getAttribute('href')?.startsWith('#')
    );

    ankors.forEach((element) =>
        element.addEventListener('click', onAnkorClick(element))
    );
};

export default ({ data, location }) => {
    const {
        title,
        content,
        date,
        modified,
        featuredMedia: featuredMediaRaw,
        seo: seoRaw,
        slug,
        id,
        article_acf: {
            widget1Image,
            widget1Url,
            widget2Image,
            widget2Url,
            widgetsEnabled,
        },
    } = data?.wpPost;

    const seo = seoRaw as Seo;
    const { node: featuredMedia } = featuredMediaRaw || {};

    const widgets = [
        { image: widget1Image, url: widget1Url },
        { image: widget2Image, url: widget2Url },
    ].filter((x) => x.url);

    const [configs, setConfigs] = useState({});

    const contentRef = useRef<HTMLDivElement>(null);

    const isLoaded = useExternalScript('ADTHIS', SCRIPT_ADTHIS_PATH);

    useEffect(() => {
        if (isLoaded) {
            (window as any)?.addthis?.layers?.refresh();
        }
    }, [isLoaded]);

    const { banner_article_bottom } = useBlogPageData();

    const setConfigsCb = useCallback(
        () =>
            setConfigs({
                url: `${SITE_URL + window?.location?.pathname}`,
                identifier: id,
                title,
            }),
        [id, title]
    );

    const isLoadedConfigs = useExternalScript('DISQUS', null, setConfigsCb);

    const headers = useMemo(() => parseHeaders(content), [content]);

    const postDescription = seo.opengraphDescription;

    const posts = data?.allWpPost?.nodes?.map(
        ({ title, excerpt, slug, featuredMedia = {} }) => ({
            title,
            excerpt,
            image: (featuredMedia as { node: SharpImage })?.node,
            url: `/${ROUTES.articles}/${slug}`,
        })
    );

    useEffect(() => {
        applyListenersToAnkors(contentRef.current);
    }, []);

    const pageUrl = `${SITE_URL}/${ROUTES.articles}/${slug}`;

    const imageSrc = getAbsoluteImageUrl(seo.opengraphImage);

    return (
        <>
            <ArticleJsonLd
                url={pageUrl}
                headline={title}
                images={imageSrc ? [imageSrc] : undefined}
                datePublished={date}
                dateModified={modified}
                authorName="Евгения Немкова"
                publisherName={COMPANY_NAME}
                publisherLogo={`${SITE_URL}/logo.png`}
                description={postDescription}
                overrides={{
                    '@type': 'Article',
                }}
            />
            <PageLayout
                contentProps={{
                    itemType: 'https://schema.org/Article',
                    itemScope: true,
                }}
                breadcrumbs={[
                    {
                        url: `/${ROUTES.articles}`,
                        title: 'Статьи',
                    },
                    {
                        title,
                        url: `${location?.pathname}`,
                    },
                ]}
                title={title}
                seoMeta={{
                    ...seo,
                    opengraphUrl: pageUrl,
                }}
            >
                <Container>
                    {featuredMedia?.localFile && (
                        <ImageWrap
                            itemScope
                            itemType="http://schema.org/ImageObject"
                        >
                            <HiddenLabel itemProp="name">{title}</HiddenLabel>
                            <CompatibleImg
                                imageStyle={{ height: '100%', width: '100%' }}
                                image={featuredMedia.localFile}
                                itemProp="contentUrl"
                            />
                            <HiddenLabel itemProp="description">
                                {postDescription}
                            </HiddenLabel>
                        </ImageWrap>
                    )}
                    <Flex>
                        <Box
                            width={widgetsEnabled ? [1, 3 / 4, 3 / 4] : '100%'}
                        >
                            <PostNav headers={headers} />
                            <RichTextView
                                ref={contentRef}
                                dangerouslySetInnerHTML={{ __html: content }}
                                itemProp="articleBody"
                            />
                        </Box>
                        {widgetsEnabled && (
                            <Box
                                width={1 / 4}
                                pl="32px"
                                display={['none', 'block', 'block']}
                            >
                                <Box position="sticky" top={['90px', '100px']}>
                                    {widgets?.map(({ image, url }, index) => (
                                        <LinkStyled
                                            key={index}
                                            href={url}
                                            target="_blank"
                                            rel="nofollow"
                                        >
                                            <CompatibleImg
                                                image={image?.localFile}
                                            />
                                        </LinkStyled>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Flex>
                    {isLoaded && (
                        <ShareButonsContainer>
                            <ShareTitle>
                                Понравилась статья? Поделитесь с друзьями
                            </ShareTitle>
                            <ShareButtons className="addthis_inline_share_toolbox" />
                        </ShareButonsContainer>
                    )}
                    <BannerContainer to={`/${ROUTES.freeProgramms}`}>
                        <CompatibleImg
                            image={banner_article_bottom.localFile}
                            alt="Бесплатные программы"
                        />
                    </BannerContainer>
                    <Box mt="48px">
                        <ShareTitle>Вам также может понравиться:</ShareTitle>
                        <MorePostsList posts={posts} />
                    </Box>
                    {isLoadedConfigs && (
                        <Comments>
                            <CommentCount config={configs} placeholder="..." />
                            <Disqus config={configs} />
                        </Comments>
                    )}
                </Container>
            </PageLayout>
        </>
    );
};

const LinkStyled = styled.a`
    display: block;
    margin-bottom: 24px;
`;

const BannerContainer = styled(Link)`
    width: 100%;
    margin-top: 32px;
    display: block;
`;

const ShareTitle = styled.div`
    color: #2e0f25;
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;
    font-family: ${FONT_GOTHAM_LIGHT};
`;

const ShareButtons = styled.div`
    display: flex;
    justify-content: center;
`;

const ShareButonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
`;

const Comments = styled.div`
    margin-top: 24px;
`;

const Container = styled(ContentMain)`
    display: block;
`;

const ImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 550px;
    border-radius: 33px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
    overflow: hidden;

    margin-bottom: 48px;

    @media ${device.laptopSmall} {
        overflow: hidden;
        margin-bottom: 36px;
        margin-right: 0;
        height: 450px;
    }

    @media ${device.mobileMedium} {
        width: 100%;
        margin-bottom: 24px;
        height: 350px;
    }
`;

export const pageQuery = graphql`
    query SlideByPath($id: String!) {
        wpPost(id: { eq: $id }) {
            id
            title
            content
            slug
            date(formatString: "YYYY-MM-DDTHH:mm:ss")
            modified(formatString: "YYYY-MM-DDTHH:mm:ss")
            seo {
                ...Seo
            }
            article_acf {
                widgetsEnabled
                widget1Image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                widget1Url
                widget2Image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                widget2Url
            }
            featuredMedia: featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100)
                        }
                    }
                }
            }
        }
        allWpPost(filter: { id: { ne: $id } }, limit: 4) {
            nodes {
                title
                excerpt
                slug
                featuredMedia: featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 400)
                            }
                        }
                    }
                }
            }
        }
    }
`;
